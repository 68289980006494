@import '../theme-bootstrap';

.elc-product-brief {
  display: flex;
  height: 100%;
  overflow-x: hidden;
  &:first-of-type {
    flex-flow: column;
  }
  .elc-short-description-container {
    &:hover {
      text-decoration: none;
      color: $color-black;
    }
  }
  .elc-product-name-section {
    flex: 0 0 auto;
    width: 100%;
  }
  .elc-product-visual-section {
    width: 100%;
    order: -2;
    position: relative;
    .elc-product-image-badge-wrapper,
    .elc-image-slider-images-wrapper {
      width: 65%;
      margin: 0 auto;
      display: flex;
    }
    .elc-carousel-section-wrapper {
      .slick-slide {
        position: relative;
      }
      .elc-slider-dot-button-active {
        &:before {
          opacity: 1;
        }
      }
    }
    .elc-image-slider-images-wrapper {
      .elc-product-badges-wrapper {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .elc-product-image-badge-wrapper {
      .elc-product-badge-wrapper {
        position: absolute;
        top: 0;
        #{$ldirection}: 0;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .elc-product-details-section {
    width: 100%;
    @include breakpoint($medium-up) {
      justify-content: flex-start;
    }
  }
  .elc-product-images-wrapper {
    margin: 0 auto;
    height: 100%;
    width: auto;
    img {
      width: 100%;
      height: auto;
      position: relative;
      #{$rdirection}: 0;
      @include breakpoint($medium-up) {
        #{$rdirection}: unset;
      }
    }
    &.has-alt-image:hover {
      img {
        display: none;
      }
      img:nth-of-type(1) {
        display: block;
      }
    }
  }
  .elc-carousel-section-wrapper {
    .elc-directional-icon {
      display: none;
    }
    .elc-slider-arrow-wrapper {
      top: auto;
      bottom: 26px;
      &:before {
        font-size: get-rem(22px);
      }
      &.slick-prev {
        #{$rdirection}: auto;
        #{$ldirection}: 35%;
      }
      &.slick-next {
        #{$rdirection}: 35%;
        #{$ldirection}: auto;
        &:before {
          #{$rdirection}: 0;
        }
      }
    }
  }
  .elc-product-display-name-wrapper {
    display: flex;
    flex-direction: column-reverse;
    margin: 0;
    &:hover {
      text-decoration: none;
      color: $color-black;
    }
  }
  .elc-product-display-name-link {
    font-family: $font--helvetica-neue-regular;
    font-size: get-rem(24px);
    display: flex;
    flex-direction: column-reverse;
    font-weight: 700;
    line-height: 1.1;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: -0.3px;
    @include breakpoint($medium-up) {
      font-size: get-rem(30px);
      line-height: 1;
    }
  }
  .elc-product-subheader,
  .elc-product-display-name-link-subheader {
    color: $color-black-op88;
    font-family: $font--helvetica-neue-regular;
    font-size: get-rem(18px);
    line-height: 1.2;
    margin: 3px 0;
    text-transform: uppercase;
    letter-spacing: 0.21px;
    font-weight: normal;
    @include breakpoint($medium-up) {
      font-size: get-rem(20px);
      line-height: 1;
    }
  }
  .elc-product-short-description-wrapper {
    color: $color-black;
    font-family: $font--helvetica-neue-light;
    font-size: get-rem(16px);
    line-height: 1.5;
    margin: 0 0 5px;
    text-transform: initial;
    width: 90%;
    @include breakpoint($xlarge-up) {
      width: 100%;
    }
  }
  .elc-product-badge {
    font-family: $font--helvetica-neue-regular;
    font-weight: 700;
    font-size: get-rem(16px);
    color: $color-black;
    line-height: 1;
    min-width: 50px;
    text-align: $ldirection;
    text-transform: uppercase;
    width: auto;
    margin: 0;
  }
  .elc-product-prices-wrapper,
  .elc-product-prices-clickable-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    float: $ldirection;
    .elc-product-prices-wrapper {
      width: 100%;
    }
  }
  .elc-sku-benefit {
    @include spp-price-format();
    font-family: $font--helvetica-neue-regular;
    width: 50%;
    float: $ldirection;
    line-height: 2.9;
    font-size: get-rem(22px);
    margin: 0;
    @include breakpoint($portrait-up) {
      font-size: get-rem(20px);
      line-height: 3.1;
    }
    @include breakpoint($landscape-up) {
      font-size: get-rem(22px);
    }
    @include breakpoint($xlarge-up) {
      font-size: get-rem(24px);
      line-height: 2.9;
    }
  }
  .elc-product-price-row-wrapper {
    margin: 20px 0;
    @include breakpoint($medium-up) {
      margin: 15px 0;
    }
  }
  .elc-price-formatted-wrapper {
    display: inline-block;
  }
  .elc-price-formatted {
    line-height: 1;
    margin: 0;
  }
  .elc-price-formatted {
    font-family: $font--helvetica-neue-bold;
    font-size: get-rem(18px);
    color: $color-black;
    line-height: 1;
    margin: 0;
    @include breakpoint($landscape-up) {
      font-size: get-rem(20px);
    }
  }
  .elc-product-price-per-unit {
    font-family: $font--helvetica-neue-regular;
    font-size: get-rem(20px);
    margin-bottom: 10px;
    @include breakpoint($landscape-up) {
      font-size: get-rem(23px);
    }
  }
  .elc-product-full-cta-wrapper {
    box-shadow: none;
    position: relative;
    padding: 0;
    background: $color-transparent;
    display: inline-block;
    width: 100%;
    @include breakpoint($medium-up) {
      min-height: 51px;
      position: unset;
    }
  }
  .elc-product-notify-me-button,
  .elc-add-to-bag-button {
    @include btn($type: 'solid');
    font-size: get-rem(13px);
    letter-spacing: $base-letter-spacing;
    min-height: 40px;
    min-width: 138px;
    height: auto;
    @include breakpoint($portrait-up) {
      font-size: get-rem(14px);
      min-width: 130px;
    }
    @include breakpoint($large-up) {
      font-size: get-rem(16px);
      min-width: 150px;
    }
    @include breakpoint($landscape-up) {
      font-size: get-rem(18px);
      min-width: 180px;
      min-height: 50px;
    }
    &:hover {
      background: $color-black;
    }
    &-disabled:hover,
    &-disabled {
      background: $color-gray;
      color: $color-white;
    }
  }
  .elc-add-to-bag-button {
    padding: 5px;
    min-width: 97px;
    min-height: 27px;
    height: 58px;
    font-size: get-rem(18px);
    letter-spacing: 0.21px;
    width: 100%;
    @include breakpoint($medium-up) {
      padding: 18px 12px;
      min-width: 135px;
      min-height: 40px;
      font-size: get-rem(16px);
      bottom: 0;
      position: absolute;
      width: 40%;
    }
  }
  .elc-product-name-wrapper {
    margin: 0 0 10px;
  }

  // Ratings & Reviews.
  .elc-rating {
    margin: 0;
    font-weight: normal;
  }
  .elc-read-reviews {
    display: none;
  }
  .elc-product-rating-wrapper {
    justify-content: flex-start;
    .elc-stars-wrapper {
      flex: 1 1 100%;
    }
    .elc-stars-simplified,
    .elc-stars-simplified-stars {
      height: 12px;
      margin: 0;
      overflow: hidden;
      position: relative;
      width: 64px;
      display: flex;
      &:before {
        background: url('#{$base-theme-path}/img/icons/src/star-outline_v2.svg') repeat-x;
        align-items: center;
        display: flex;
        height: 12px;
        position: absolute;
        width: 77px;
        content: '';
      }
    }
    .elc-stars-simplified-stars:before {
      background: url('#{$base-theme-path}/img/icons/src/star_v2.svg') repeat-x;
    }
  }
  .elc-rating {
    margin: 0;
    font-weight: normal;
    padding: 0;
    height: auto;
    background: transparent;
    line-height: unset;
    &-component {
      margin: 12px 0;
      padding: 0;
      @include breakpoint($medium-up) {
        margin: 24px 0;
      }
    }
    &-button {
      display: flex;
    }
    &-reviews {
      color: $color-black;
      order: 3;
    }
  }
  .elc-read-reviews-link,
  .elc-reviews-number,
  .elc-write-first-review {
    color: $color-black;
    font-family: $font--helvetica-neue-regular;
    letter-spacing: $base-letter-spacing;
    margin-top: 0;
    padding: 0;
    border: none;
    text-decoration: none;
  }
  .elc-reviews-number {
    padding-#{$ldirection}: 4px;
    font-family: $font--helvetica-neue-regular;
    font-size: get-rem(16px);
    color: $color-black;
    margin: 0;
    @include breakpoint($landscape-up) {
      font-size: get-rem(13px);
    }
  }
  .elc-write-first-review {
    border: none;
  }
  .elc-average-rating-text {
    display: none;
  }

  .elc-media-asset {
    .elc-product-images-wrapper {
      min-height: initial;
      min-width: initial;
    }
  }
  // Sizes.
  .elc-slider-view-wrapper {
    padding: 0;
  }
  .elc-size-picker {
    &-box {
      @include btn($type: 'outline');
    }
    &-box-selected {
      @include btn($type: 'solid');
    }
    &-box,
    &-box-selected {
      @include swap_direction(margin, 12px 8px 5px 0);
      font-family: $font--helvetica-neue-regular;
      font-size: get-rem(16px);
      padding: 10px 6px;
      line-height: 1.5;
      width: auto;
      @include breakpoint($medium-up) {
        font-size: get-rem(12px);
        padding: 12px;
      }
      @include breakpoint($portrait-up) {
        font-size: get-rem(12px);
        padding: 10px 8px;
      }
      @include breakpoint($large-up) {
        font-size: get-rem(16px);
        padding: 12px 14px;
      }
      @include breakpoint($landscape-up) {
        min-width: unset;
      }
      @include breakpoint($xlarge-up) {
        @include swap_direction(padding, 12px 16px 10px 14px);
      }
    }
    &-wrapper {
      .elc-slider-view-wrapper {
        .slick-track {
          justify-content: flex-start;
        }
        .slick-slide {
          width: auto !important;
        }
      }
    }
  }
  .elc-selected-size {
    font-family: $font--helvetica-neue-regular;
    font-size: get-rem(16px);
    font-weight: normal;
    color: $color-black;
    line-height: 1.5;
    margin: 20px 0 0;
    width: auto;
  }
  .elc-selected-size-label {
    display: none;
    & + .elc-selected-size {
      display: none;
    }
  }
  // Product Details Link.
  .elc-cta-link {
    display: none;
  }
  .elc-rating-component {
    @include breakpoint($medium-up) {
      margin: 10px 0;
    }
  }
  .elc-rating-reviews {
    @include breakpoint($medium-up) {
      margin: 0 2px;
    }
  }
}

.elc-responsive-modal-mask {
  .elc-product-notify-me {
    &-pop-up-wrapper,
    &-confirmation-wrapper {
      min-height: 230px;
      @include breakpoint($landscape-up) {
        width: 544px;
        min-height: 408px;
      }
    }
    &-pop-up-close-button,
    &-confirmation-close-button {
      margin: 15px;
      background-color: unset;
      &-icon {
        background-color: $color-dark-gray;
      }
    }
    &-title-label,
    &-confirmation-title {
      font-family: $font--helvetica-neue-bold;
      font-size: 14px;
      letter-spacing: 0.25px;
      line-height: 1.45;
      margin: 0;
      text-align: $ldirection;
    }
    &-form-wrapper {
      margin: 10px 0;
    }
    &-confirmation-content {
      display: flex;
      flex: 1 1 auto;
      flex-flow: wrap;
      width: 100%;
      & > div:first-child {
        margin: 0;
        .elc-ok-icon {
          text-align: $ldirection;
          width: 20px;
          height: 20px;
        }
      }
    }
    &-content,
    &-confirmation-content {
      font-family: $font--helvetica-neue-regular;
      padding: 40px 15px 20px;
      max-width: inherit;
      @include breakpoint($landscape-up) {
        padding: 40px 35px 0;
      }
    }
    &-info {
      @include breakpoint($landscape-up) {
        position: absolute;
        bottom: 20px;
      }
      &:hover {
        .elc-product-gdpr-popup {
          visibility: visible;
        }
      }
    }
    &-info-your-data {
      cursor: pointer;
    }
    &-info-button {
      background: $color-transparent;
      padding: 0;
      &:hover {
        background: $color-transparent;
      }
    }
    &-confirmation-content-text {
      line-height: 1.45;
      text-align: $ldirection;
      width: 100%;
    }
    &-confirmation-continue-button {
      width: 100%;
      position: relative;
    }
    &-submit-button,
    &-confirmation-continue-button {
      font-family: $font--helvetica-neue-bold;
      font-size: 13px;
      margin-top: 15px;
      border-radius: 0;
      height: 40px;
      &:hover,
      &:active {
        background: $color-black;
      }
      @include breakpoint($landscape-up) {
        font-size: 18px;
        height: 50px;
      }
    }
  }
  .elc-checkbox-check-area {
    position: inherit;
  }
  .elc-product-gdpr-popup {
    bottom: 35px;
    position: absolute;
    padding: 13px;
    visibility: hidden;
    background-color: $color-light-grey;
    border-radius: 6px;
  }
}

.sd-product-grid {
  .is-slider,
  .elc-product-carousel-container {
    padding-bottom: 10px;
    margin: 0;
    @include breakpoint($medium-up) {
      margin: 0 10px;
      padding: 0 20px;
    }
    .slick-prev,
    .slick-next {
      height: 50px;
      width: 50px;
      opacity: 0.75;
      &,
      &:hover {
        background: $color-black;
      }
      &.slick-disabled {
        opacity: 0.4;
        pointer-events: none;
      }
    }
    .elc-icon {
      background: $color-white;
      margin: 16px auto;
      padding: 10px;
    }
    .elc-product-carousel-slider-view {
      .elc-slider-arrow-wrapper {
        &.slick-next {
          #{$rdirection}: -20px;
          @include breakpoint($medium-up) {
            #{$rdirection}: -48px;
          }
        }
        &.slick-prev {
          #{$ldirection}: -20px;
          @include breakpoint($medium-up) {
            #{$ldirection}: -52px;
          }
        }
        &.slick-arrow {
          top: 184px;
          @include breakpoint($medium-up) {
            top: 50%;
          }
        }
      }
    }
    .has-alt-image {
      &:hover {
        .elc-img {
          opacity: 0;
        }
        .elc-img--alt {
          opacity: 1;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .slick-slide {
      margin: 0;
      @include breakpoint($medium-up) {
        margin: 0 4px;
      }
      .slick-next,
      .slick-prev {
        display: none;
      }
    }
    .elc-product-brief {
      position: relative;
      padding-bottom: 60px;
      flex-direction: column;
      &:first-of-type {
        flex-flow: wrap;
      }
      .slick-slider & {
        @include breakpoint($medium-up) {
          padding-#{$rdirection}: 5px;
        }
      }
      .elc-product-badge-wrapper,
      .elc-rating-reviews {
        display: none;
      }
    }
    .elc-product-full-cta-wrapper {
      @include breakpoint($medium-up) {
        position: absolute;
        bottom: 10px;
        display: block;
      }
    }
    .elc-slider-view-wrapper {
      padding: 0;
    }
    .elc-product-name-section {
      order: 2;
      flex: none;
    }
    .elc-product-grid-header {
      font-size: get-rem(32px);
      font-family: $font--heading;
      line-height: 1;
      margin-bottom: 12px;
      @include breakpoint($medium-up) {
        font-size: get-rem(36px);
        margin-bottom: 20px;
      }
    }
    .elc-product-grid-header-wrapper {
      align-items: flex-start;
      padding-#{$ldirection}: 0;
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 30px;
      }
    }
    .elc-product-visual-section {
      width: 100%;
      height: 326px;
      order: 1;
      background-color: $color-white;
    }
    .elc-product-details-section {
      width: 100%;
      order: 3;
    }
    .elc-product-images-wrapper {
      img {
        height: 326px;
        width: auto;
        margin: 0 auto;
      }
      &.has-alt-image:hover {
        img {
          display: block;
        }
        img:nth-of-type(1) {
          display: none;
        }
      }
    }
    .elc-product-display-name-link {
      font-size: get-rem(16px);
      @include breakpoint($medium-up) {
        font-size: get-rem(18px);
      }
    }
    .elc-product-subheader,
    .elc-product-display-name-link-subheader {
      margin: 10px 0 3px;
      font-size: get-rem(16px);
      font-weight: normal;
      @include breakpoint($medium-up) {
        font-size: get-rem(18px);
        margin: 3px 0;
      }
    }
    .elc-product-short-description-wrapper {
      font-size: get-rem(16px);
      width: 100%;
      @include breakpoint($medium-up) {
        font-size: get-rem(14px);
        width: 90%;
      }
    }
    .elc-product-price-row-wrapper {
      margin: 0 0 8px;
      @include breakpoint($medium-up) {
        margin: 0 0 5px;
      }
    }
    .elc-price-formatted {
      font-size: get-rem(13px);
      @include breakpoint($medium-up) {
        font-size: get-rem(16px);
      }
    }
    .elc-product-price-per-unit {
      font-size: get-rem(10px);
    }
    .elc-product-notify-me-button,
    .elc-add-to-bag-button {
      padding: 5px;
      min-width: 97px;
      min-height: 27px;
      @include breakpoint($medium-up) {
        min-width: 135px;
        min-height: 40px;
        font-size: get-rem(16px);
      }
    }
    .elc-product-name-wrapper {
      margin: 0 0 5px;
    }
    .elc-stars-simplified,
    .elc-stars-simplified-stars {
      &,
      &::before {
        height: 11px;
        width: 57px;
        @include breakpoint($medium-up) {
          height: 12px;
          width: 64px;
        }
      }
    }
    .elc-rating-component {
      margin: 0 0 5px;
    }
    .elc-size-picker-box,
    .elc-size-picker-box-selected {
      @include swap_direction(margin, 0 8px 5px 0);
      font-size: get-rem(10px);
      padding: 7px 6px;
    }
    .elc-size-picker-wrapper {
      .slick-slide {
        margin-#{$ldirection}: 0;
      }
    }
    .elc-selected-size {
      @include swap_direction(margin, 0 8px 5px 0);
      font-size: get-rem(10px);
      padding: 7px 0;
      @include breakpoint($medium-up) {
        font-size: get-rem(12px);
      }
    }
  }
}

// Moved from React inline style.
.elc-size-picker-container {
  .slick-track {
    display: flex;
    width: inherit !important;
  }
}